import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"


const PortfolioIndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      LogoTECSight: file(relativePath: { eq: "logo-tecsight.svg" }) {
        publicURL
      },
      LogoTSC: file(relativePath: { eq: "logo-temora-shire.svg" }) {
          publicURL
      },
      LogoCSU: file(relativePath: { eq: "logo-csu.svg" }) {
        publicURL
      },
      LogoAudinate: file(relativePath: { eq: "logo-audinate.svg" }) {
          publicURL
      },
      LogoG1G: file(relativePath: { eq: "logo-g1g.svg" }) {
          publicURL
      },
      LogoDSM: file(relativePath: { eq: "logo-dsm.svg" }) {
          publicURL
      },
      LogoWisetech: file(relativePath: { eq: "logo-wisetech-global.svg" }) {
          publicURL
      },
      LogoValen: file(relativePath: { eq: "logo-valen.svg" }) {
          publicURL
      },
      LogoWCC: file(relativePath: { eq: "logo-wcclp.svg" }) {
          publicURL
      },
      LogoPPTP: file(relativePath: { eq: "logo-pptp.png" }) {
          publicURL
      },
    }
  `);
  
  return (
    <Layout>
      <Seo 
        title="Portfolio"
        description="A selection of our work"
      />
      <div className="mx-4 mb-10 lg:max-w-screen-lg lg:mx-auto"> 
        <h1 className="text-5xl font-bold text-anomaly-red">Portfolio</h1>
      </div>

      <div className="grid max-w-screen-lg grid-cols-1 gap-8 p-8 mx-auto lg:grid-cols-2">
        <section className="flex flex-col justify-between bg-white shadow-2xl rounded-xl">
          <div className="flex flex-col justify-between p-6 bg-white rounded-t-xl">
            <img className="" src={data.LogoTSC.publicURL} width="180" alt="Temora Shire Council"/>
            <h1 className="w-full pt-2 text-3xl font-bold mt-28 text-anomaly-red">Membership Management</h1>
            <p className="mt-1 text-gray-800">Open sourced stack for membership management.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-b-xl">
            <p className="text-white">
              Contactless paid access to Temora Shire Council's recreation centre, powered by Gallagher
              and Stripe.
            </p>
            <Link className="flex items-center mt-4 text-lg text-white" to="/portfolio/tsc">
              Learn more
              <ArrowRightIcon className="h-4 ml-2"/>
            </Link>
          </div>
        </section>
        <section className="flex flex-col justify-between bg-white shadow-2xl rounded-xl">
          <div className="flex flex-col justify-between p-6 bg-white rounded-t-xl">
            <img className="" src={data.LogoTECSight.publicURL} width="240" alt="TECSight"/>
            <h1 className="w-full pt-2 text-3xl font-bold mt-28 text-anomaly-red">TECSight Portal</h1>
            <p className="mt-1 text-gray-800">A mobile first portal for their customers.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-b-xl">
            <p className="text-white">
              A modern portal for Hutcheon and Pearce customers making John Deere technology more accessible.
            </p>
            <Link className="flex items-center mt-4 text-lg text-white" to="/portfolio/tecsight">
              Learn more
              <ArrowRightIcon className="h-4 ml-2"/>
            </Link>
          </div>
        </section>
        <section className="flex flex-col justify-between bg-white shadow-2xl rounded-xl">
          <div className="flex flex-col justify-between p-6 bg-white rounded-t-xl">
            <img className="" src={data.LogoCSU.publicURL} width="240" alt="Charles Sturt University"/>
            <h1 className="w-full pt-2 text-3xl font-bold mt-28 text-anomaly-red">CourseSpace</h1>
            <p className="mt-1 text-gray-800">A visionary approach to course design.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-b-xl">
            <p className="text-white">
            Building an industry-first collaboration program to help with course compliance at Charles Sturt University.
            </p>
            <Link className="flex items-center mt-4 text-lg text-white" to="/portfolio/csu">
              Learn more
              <ArrowRightIcon className="h-4 ml-2"/>
            </Link>
          </div>
        </section>
        <section className="flex flex-col justify-between bg-white shadow-2xl rounded-xl">
          <div className="flex flex-col justify-between p-6 bg-white rounded-t-xl">
            <img className="" src={data.LogoDSM.publicURL} width="170" alt="DSM"/>
            <h1 className="w-full text-3xl font-bold mt-28 text-anomaly-red">Digital Permit System</h1>
            <p className="mt-1 text-gray-800">Digitising precision and safety.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-b-xl">
            <p className="text-white">
              An organisation-wide, multilingual system to simplify workflow and further reduce potential for error.
            </p>
            <Link className="flex items-center mt-4 text-lg text-white" to="/portfolio/dsm">
              Learn more
              <ArrowRightIcon className="h-4 ml-2"/>
            </Link>
          </div>
        </section>
        <section className="flex flex-col justify-between bg-white shadow-2xl rounded-xl">
          <div className="flex flex-col justify-between p-6 bg-white rounded-t-xl">
            <img className="" src={data.LogoWCC.publicURL} width="80" alt="WCC LP Logo"/>
            <h1 className="w-full text-3xl font-bold mt-28 text-anomaly-red">Apps for Education</h1>
            <p className="mt-1 text-gray-800">Keeping our heritage in reach.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-b-xl">
            <p className="text-white">
              Building ways for future generations to interactively learn Australia's indigenous languages.
            </p>
            <Link className="flex items-center mt-4 text-lg text-white" to="/portfolio/wcc/">
              Learn more
              <ArrowRightIcon className="h-4 ml-2"/>
            </Link>
          </div>
        </section>
        <section className="flex flex-col justify-between bg-white shadow-2xl rounded-xl">
          <div className="flex flex-col justify-between p-6 bg-white rounded-t-xl">
            <img className="" src={data.LogoG1G.publicURL} width="280" alt="G1 Goldmine"/>
            <h1 className="w-full text-3xl font-bold pt-7 mt-28 text-anomaly-red">Stripe Integration</h1>
            <p className="mt-1 text-gray-800">Automating billing and subscriptions.</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-b-xl">
            <p className="text-white">
            Creating a custom experience with complex subscription schedules to solve a complicated payment system.
            </p>
            <Link className="flex items-center mt-4 text-lg text-white" to="/portfolio/g1g">
              Learn more
              <ArrowRightIcon className="h-4 ml-2"/>
            </Link>
          </div>
        </section>
      </div>
    </Layout>
 );
};
export default PortfolioIndexPage
